html,
body {
  overflow-x: hidden;
}

.loading {
      color: #588157;
        font-weight: bold;
        font-size: 2.1rem;
        font-family: 'Sofia';
}
