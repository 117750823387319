.iconSize {
    width: 5rem;
    height: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

h2 {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: sans-serif;
    color: #588157;
}

.servicesContainer {
    border: 1px solid black;
    padding: 2rem 2rem;
    border-radius: 10px;

}

.main{
    border-bottom: 1px solid #9aaaa1;
    padding-bottom: 4rem;
}
