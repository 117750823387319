.name {
    color: #588157; 
    font-weight: bold;
    font-size: 2.1rem;
    font-family: 'Sofia';
}

.name:hover {
    text-decoration: underline;
    color: #344E41
}

.name:active {
    text-decoration: none;
        color: #344E41
    }

.page {
    background-color: #ebebdf;
}

.navLinks {
    gap: 2rem;
}

.link {
    color: #344E41;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.1rem;
}

.link:hover{
    text-decoration: underline;
    color: #588157;
}

.link:active {
    text-decoration: none;
        color: #588157;
}