.design {
    margin-left: 10rem;
}

p {
    font-style: italic;
    font-size: 1.8rem;
    text-align: center;
    padding-top: 12rem;
}

.main {
    border-bottom: 1px solid #9aaaa1;
    padding-bottom: 4rem;
}