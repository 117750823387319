.footer {
    background-color: #DAD7CD;
    margin-top: 5rem;
}

h3 {
    color: #588157;
        font-weight: bold;
        font-size: 2.1rem;
        font-family: 'Sofia';
}

.text {
    font-size: 1.2rem;
    color: #3A5A40;
    font-weight: 400;
}

.footerServices {
    margin-left: 3rem;
}

.copy {
    font-weight: bolder;
    color: #344E41;
}

