.imageStyle {
    height: 45rem;
    width: 45rem;
    margin-left: -19rem;
}

.rowStyle{
    align-items: center;
    /* width: 100%; */
    justify-content: space-around;
    border-bottom: 1px solid #344E41;

}

.title {
color: #3A5A40;
font-size: 6rem;
font-family: Arial, Helvetica, serif;
}

.paragraph {
    color: #3A5A40;
    font-size: 2rem;
    font-style: italic;
}

.intro {
    width:fit-content;
}