.design {
    margin-left: 10rem;
}

p {
    font-style: italic;
    font-size: 1.8rem;
    text-align: center;
    padding-top: 12rem;
}

.ContactForm {
    padding: 0 15rem;
    margin-left: 6rem;
}